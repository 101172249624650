import { Environment } from './environment-model';

export const environment: Environment = {
  production: false,
  emulators: false,

  firebaseConfig: {
    apiKey: 'AIzaSyDxHrVQAbFZS5unA48bFyJYBHKRi8wHDfw',
    authDomain: 'dev.sharendipity.com',
    projectId: 'sharendipity-dev',
    storageBucket: 'sharendipity-dev.appspot.com',
    messagingSenderId: '560031224525',
    appId: '1:560031224525:web:3c509773fe360c97cdcf84',
    measurementId: 'G-00Y4KX9VSB',
  },
  vapidKey:
    'BLrUe--9LQ_EgQzAY36CrmAdivk6b1hlNaGrBadj7cbIz1VZWc5MfuqVSvE1EA0WqCPlilrdqljQ8TmHFkggUsQ',
};

import { Injectable } from '@angular/core';
import { where, orderBy } from '@angular/fire/firestore';
import {
  Connection,
  ConnectionInfo,
  ConnectionStatus,
  NewUser,
  SharendipityUser,
} from '@sharendipity/models';
import { filter, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService
  ) {}

  connections(): Observable<ConnectionInfo[]> {
    return this.authService.user$.pipe(
      filter((user) => !!user),
      switchMap((user) => {
        return this.firestoreService.collection<Connection>(
          `connections`,
          where(
            'users',
            'array-contains',
            this.firestoreService.documentReference(`users/${user.uid}`)
          )
        );
      }),
      switchMap(async (connections) => {
        const userId = await firstValueFrom(
          this.authService.user$.pipe(map((user) => user.uid))
        );
        const connectionPromise = connections
          .map(
            (connection) =>
              connection.users.filter((user) => user.id !== userId)[0]
          )
          .map(
            async (user) =>
              await firstValueFrom(
                this.firestoreService
                  .document<SharendipityUser>(`users/${user.id}`)
                  .pipe(
                    map(
                      ({ id, name, email, photoURL }) =>
                        ({
                          id,
                          name,
                          email,
                          photoURL,
                        } as ConnectionInfo)
                    )
                  )
              )
          );

        const participants = await Promise.all(connectionPromise);
        return this.sortConnections(participants);
      })
    );
  }

  /**
   * Create a new user and connect that user with the currently logged in user
   */
  async newUserAndConnect(newUser: NewUser): Promise<string> {
    // Search for user by email
    const document = await this.firestoreService.collectionDocs(
      'users',
      where('email', '>=', newUser.email),
      where('email', '<=', newUser.email + '\uf8ff')
    );
    // if (document.empty) {

    // }

    const newUserRecord = await this.firestoreService.addDocument(`users`, {
      email: newUser.email,
      name: newUser.name,
      phoneNumber: newUser.phoneNumber,
      created: new Date(),
    });

    // TODO: Send this new user an email or a text
    await this.newConnection(newUserRecord.id);

    return newUserRecord.id;
  }

  /**
   * Create a new connection with the currently logged in user and the newConnectionId
   * @param newConnectionId User id of the new connection to be made
   */
  async newConnection(newConnectionId: string) {
    const userId = await firstValueFrom(
      this.authService.user$.pipe(map((user) => user.uid))
    );
    const newConnection: Connection = {
      created: new Date(),
      users: [
        this.firestoreService.documentReference(`users/${userId}`),
        this.firestoreService.documentReference(`users/${newConnectionId}`),
      ],
      status: ConnectionStatus.PENDING,
    };
    return this.firestoreService.addDocument(`connections`, newConnection);
  }

  /**
   * Sort connections alphabetically
   * @param connections
   * @returns
   */
  private sortConnections(connections: ConnectionInfo[]) {
    return connections.sort((a, b) =>
      a?.name.toUpperCase() > b?.name.toUpperCase()
        ? 1
        : a?.name.toUpperCase() < b?.name.toUpperCase()
        ? -1
        : 0
    );
  }
}

export const AuthTranslations = {
  confirmPasswordRequired: 'i18n.auth.validation.confirmPasswordRequired',
  emailExists: 'i18n.auth.validation.emailExists',
  emailInvalid: 'i18n.auth.validation.invalidEmail',
  emailRequired: 'i18n.auth.validation.emailRequired',
  incorrectEmailPassword: 'i18n.auth.validation.incorrectEmailPassword',
  passwordLengthInvalid: 'i18n.auth.validation.passwordLengthInvalid',
  passwordMismatch: 'i18n.auth.validation.passwordMismatch',
  passwordRequired: 'i18n.auth.validation.passwordRequired',
  passwordResetEmailSent: 'i18n.auth.validation.passwordResetEmailSent',
  pleaseEnterValidEmail: 'i18n.auth.validation.pleaseEnterValidEmail',
  unableToSignIn: 'i18n.auth.validation.unableToSignIn',
};

import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  collectionGroup,
  deleteDoc,
  doc,
  docData,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  setDoc,
  WithFieldValue,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: Firestore) {}

  /**
   * Get collection data from a certain path
   * @param path
   * @param queryContraints
   * @returns
   */
  public collection<T>(
    path: string,
    ...queryContraints: QueryConstraint[]
  ): Observable<T[]> {
    const col = collection(this.firestore, path);
    const q = query(col, ...queryContraints);
    return collectionData(q, { idField: 'id' }) as Observable<T[]>;
  }

  public collectionGroup(path: string, ...queryContraints: QueryConstraint[]) {
    const col = collectionGroup(this.firestore, path);
    const q = query(col, ...queryContraints);
    return getDocs(q);
  }

  public collectionGroupStream<T>(
    path: string,
    ...queryContraints: QueryConstraint[]
  ) {
    const col = collectionGroup(this.firestore, path);
    const q = query(col, ...queryContraints);
    return collectionData(q, { idField: 'id' }) as Observable<T[]>;
  }

  /**
   * Get document data from a certain path
   * @param path
   * @returns
   */
  public document<T>(path: string): Observable<T> {
    const d = doc(this.firestore, path);
    return docData(d, { idField: 'id' }) as Observable<T>;
  }

  /**
   * Get document data from a certain collection
   * @param path
   * @returns
   */
  public collectionDocs(path: string, ...queryContraints: QueryConstraint[]) {
    const col = collection(this.firestore, path);
    const q = query(col, ...queryContraints);
    return getDocs(q);
  }

  /**
   * Get document data from a certain path
   * @param path
   * @returns
   */
  public documentReference(path: string): DocumentReference {
    return doc(this.firestore, path);
  }

  /**
   * Get document snapshot
   * @param reference
   * @returns
   */
  public getDoc<T>(reference: DocumentReference): Promise<DocumentSnapshot<T>> {
    return getDoc(reference) as Promise<DocumentSnapshot<T>>;
  }

  /**
   * Add a document to a collection
   * @param path
   * @param data
   * @returns
   */
  public addDocument<T extends WithFieldValue<DocumentData>>(
    path: string,
    data: T
  ): Promise<DocumentReference<T>> {
    const col = collection(this.firestore, path);
    return addDoc(col, data) as Promise<DocumentReference<T>>;
  }

  public setDocument<T extends WithFieldValue<DocumentData>>(
    path: string,
    data: T
  ): Promise<void> {
    const d = doc(this.firestore, path);
    return setDoc(d, data, { merge: true });
  }

  public deleteDocument(path: string): Promise<void> {
    const d = doc(this.firestore, path);
    return deleteDoc(d);
  }
}

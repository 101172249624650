import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import {
  Collection,
  EditItem,
  Item,
  ItemTransactionType,
  LendItem,
  NewItem,
} from '@sharendipity/models';
import { difference } from 'lodash-es';
import {
  concatAll,
  firstValueFrom,
  from,
  map,
  Observable,
  switchMap,
  toArray,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '../auth/auth.service';
import { ConnectionService } from '../connection/connection.service';
import { FirestoreService } from '../firestore/firestore.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  constructor(
    private authService: AuthService,
    private connectionService: ConnectionService,
    private firestoreService: FirestoreService,
    private storageService: StorageService
  ) {}

  /**
   * Get all collection ids where an item exists within its subcollection
   * of items. This also filters out default collections.
   * @param itemId
   * @returns
   */
  public async getCollectionIdsForItem(itemId: string) {
    const docs = await this.firestoreService.collectionGroup(
      `items`,
      where(
        'item',
        '==',
        this.firestoreService.documentReference(`items/${itemId}`)
      )
    );
    const ids = await docs.docs.reduce(async (filtered, snapshot) => {
      const filter = await filtered;
      const collectionId = snapshot.ref.parent?.parent?.id ?? '';
      const collectionDoc = await this.firestoreService.getDoc(
        this.firestoreService.documentReference(`collections/${collectionId}`)
      );

      const collection = collectionDoc.data() as Collection;
      if (!collection.default) {
        filter.push(collectionId);
      }

      return filter;
    }, Promise.resolve<string[]>([]));
    return ids;
  }

  /**
   * Create an item in Firestore. Also, add the item to the users default
   * collection and add that item to any collections selected.
   * @param item
   */
  public async createItem(item: NewItem) {
    const user = await firstValueFrom(this.authService.user$);
    const { title, description, collections, image } = item;

    // Create the item in Firestore
    const newItem = await this.firestoreService.addDocument(`items`, {
      title,
      description,
      created: new Date(),
      owner: this.firestoreService.documentReference(`users/${user.uid}`),
    });
    const newItemId = newItem.id;

    await this.addImageToItem(image, newItemId);
    await this.addItemToDefaultCollection(newItemId);

    collections.forEach(async (collectionId: string) => {
      await this.addItemToCollection(collectionId, newItemId);
    });
  }

  /**
   * Get all image urls associated with an item
   * @param itemId
   */
  public getImageUrlsForItem(itemId: string): Observable<string[]> {
    return from(this.storageService.listImages(`items/${itemId}`)).pipe(
      map((listResult) =>
        listResult.items.map((reference) => {
          return this.storageService.getDownloadURL(reference.fullPath);
        })
      ),
      switchMap((promises) => from(promises).pipe(concatAll(), toArray()))
    );
  }

  /**
   * Update the title, description and collections of an item
   * @param item
   * @param currentCollections
   */
  public async updateItem(item: EditItem, currentCollections: string[]) {
    const { id, title, description, collections } = item;
    const added = difference(collections, currentCollections);
    const deleted = difference(currentCollections, collections);

    await this.firestoreService.setDocument(`items/${id}`, {
      title,
      description,
      updated: new Date(),
    });

    added.forEach(async (collectionId: string) => {
      await this.addItemToCollection(collectionId, id);
    });

    deleted.forEach(async (collectionId: string) => {
      await this.removeItemFromCollection(collectionId, id);
    });
  }

  /**
   * Lend an item to a user
   * @param itemId
   * @param recipientId
   */
  public async lendItem(itemId: string, lendItemObject: LendItem) {
    // If the lended item is to a new connection, create the user first
    if (!lendItemObject.id && lendItemObject.newUser) {
      try {
        const newUserId = await this.connectionService.newUserAndConnect(
          lendItemObject.newUser
        );
        lendItemObject.id = newUserId;
      } catch (error) {
        console.log(`Could not create new user`, error);
      }
    }

    const transaction = await this.firestoreService.addDocument(
      `transactions`,
      {
        created: new Date(),
        type: ItemTransactionType.LEND,
        item: this.firestoreService.documentReference(`items/${itemId}`),
        recipient: this.firestoreService.documentReference(
          `users/${lendItemObject.id}`
        ),
      }
    );
    await this.firestoreService.setDocument(`items/${itemId}`, {
      transaction: this.firestoreService.documentReference(transaction.path),
    });
  }

  public async returnItem(itemId: string) {
    const snapshot = await this.firestoreService.getDoc<Item>(
      this.firestoreService.documentReference(`items/${itemId}`)
    );
    if (!snapshot.exists()) {
      return null;
    }

    const currentTransactionId = snapshot.data().transaction?.id;

    await this.firestoreService.setDocument(
      `transactions/${currentTransactionId}`,
      {
        completed: new Date(),
      }
    );
    await this.firestoreService.setDocument(`items/${itemId}`, {
      transaction: null,
    });
    return 'complete';
  }

  private async addImageToItem(image: string | undefined, itemId: string) {
    if (image) {
      try {
        await this.storageService.uploadString(
          image,
          `items/${itemId}/${uuidv4()}`
        );
      } catch (error) {
        console.error('Could not upload image', error);
      }
    }
  }

  /**
   * Add the item reference to the users default collection
   * @param itemId
   * @returns
   */
  private async addItemToDefaultCollection(itemId: string) {
    const defaultCollection = await firstValueFrom(
      this.authService.sharendipityUser$.pipe(
        map((user) => user.defaultCollection)
      )
    );
    try {
      await this.addItemToCollection(defaultCollection.id, itemId);
    } catch (error) {
      console.log('Could not add item to default collection', error);
    }
  }

  /**
   * Add the item reference to the given collection
   * @param collectionId
   * @param itemId
   * @returns
   */
  private async addItemToCollection(collectionId: string, itemId: string) {
    return this.firestoreService.setDocument(
      `collections/${collectionId}/items/${itemId}`,
      {
        added: new Date(),
        item: this.firestoreService.documentReference(`items/${itemId}`),
      }
    );
  }

  /**
   * Remove the item reference from the given collection
   * @param collectionId
   * @param itemId
   * @returns
   */
  private async removeItemFromCollection(collectionId: string, itemId: string) {
    return this.firestoreService.deleteDocument(
      `collections/${collectionId}/items/${itemId}`
    );
  }
}

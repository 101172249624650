export enum FunctionSecretKey {
  ALGOLIA_API_KEY = 'ALGOLIA_API_KEY',
  ALGOLIA_APP_ID = 'ALGOLIA_APP_ID',
  ALGOLIA_ITEM_INDEX = 'ALGOLIA_ITEM_INDEX',
  BASE_URL = 'BASE_URL',
  ENV = 'ENV',
  MAILGUN_API_KEY = 'MAILGUN_API_KEY',
  MAILGUN_DOMAIN = 'MAILGUN_DOMAIN',
  MAILGUN_FROM_EMAIL = 'MAILGUN_FROM_EMAIL',
  MAILGUN_USERNAME = 'MAILGUN_USERNAME',
}

import { Injectable } from '@angular/core';
import {
  Functions,
  httpsCallable,
  HttpsCallableResult,
} from '@angular/fire/functions';
import { HttpsCallableName } from '@sharendipity/models';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(private functions: Functions) {}

  callable<T>(
    name: HttpsCallableName,
    params: any = {}
  ): Promise<HttpsCallableResult<T>> {
    const callable = httpsCallable<any, T>(this.functions, name);
    return callable({ ...params });
  }
}

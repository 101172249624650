import { DocumentReference, Timestamp } from '@angular/fire/firestore';
import { BasicUserInfo } from '../user/basic-user';

export interface Chat {
  created: Timestamp;
  id?: string;
  participants: Array<DocumentReference>;
  participantsInfo?: ChatParticipantInfo;
  participantsInfoArray?: Array<BasicUserInfo>;
}

export interface ChatParticipantInfo {
  [id: string]: BasicUserInfo;
}

export interface Message {
  id?: string;
  created: Timestamp;
  message: string;
  sender: DocumentReference;
}

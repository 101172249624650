import { Injectable } from '@angular/core';
import { Analytics, EventParams, logEvent } from '@angular/fire/analytics';
import { AnalyticEventName } from '@sharendipity/shared/utils/constants';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private analytics: Analytics) {}

  public logEvent(eventName: AnalyticEventName, eventParams: EventParams = {}) {
    logEvent(this.analytics, eventName, eventParams);
  }
}

import { DocumentReference, Timestamp } from '@angular/fire/firestore';

export interface Item {
  created?: Timestamp | Date;
  description: string;
  id?: string;
  keywords?: string[];
  owner: DocumentReference;
  title: string;
  transaction?: DocumentReference;
}

export interface EditItem {
  id: string;
  title: string;
  description: string;
  collections: string[];
  image?: string;
}

export interface NewItem {
  description: string;
  title: string;
  collections: Array<string>;
  image?: string;
}

export interface CollectionItem {
  added?: Timestamp | Date;
  item?: DocumentReference;
}

export interface SearchItem {
  description: string;
  lastmodified: number;
  objectID: string;
  path: string;
  title: string;
}

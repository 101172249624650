import { Notification } from '@capacitor-firebase/messaging';

export interface NotificationPayload {
  notification?: Notification;
  data?: unknown;
  fcmOptions?: FcmOptionsInternal;
  messageType?: MessageType;
  isFirebaseMessaging?: boolean;
  from: string;
  fcmMessageId: string;
  collapse_key: string;
}

export interface FcmOptionsInternal {
  link?: string;
  analytics_label?: string;
}

export enum MessageType {
  PUSH_RECEIVED = 'push-received',
  NOTIFICATION_CLICKED = 'notification-clicked',
}

export interface NotificationData {
  url?: string;
}

import { DocumentReference, Timestamp } from '@angular/fire/firestore';

export enum ConnectionStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export interface Connection {
  created: Timestamp | Date;
  users: DocumentReference[];
  status: ConnectionStatus;
}

export interface ConnectionInfo {
  id: string;
  name: string;
  email: string;
  photoURL: string;
}

export interface NewConnection {
  name: string;
  email: string;
  phoneNumber: string;
}

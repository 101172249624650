import { DocumentReference, Timestamp } from '@angular/fire/firestore';

export interface ItemTransaction {
  created?: Timestamp | Date;
  completed?: Timestamp | Date;
  type?: ItemTransactionType;
  item?: DocumentReference;
  recipient?: DocumentReference;
}

export enum ItemTransactionType {
  LEND = 'lend',
}

import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@sharendipity/shared/data-access/services';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkAuth(state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkAuth(state);
  }

  private checkAuth(state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      map((user: User) => {
        if (!user) {
          // Allow access if there is no user and they are already at create or login
          if (
            state.url.includes('auth/login') ||
            state.url.includes('auth/create')
          ) {
            return true;
          }

          this.router.navigate(['auth/login'], {
            queryParams: { returnUrl: state.url },
            queryParamsHandling: 'merge',
          });
          return false;
        }

        // There is a user that is logged in that has not verified their email
        if (!user.emailVerified && !state.url.includes('auth/verify')) {
          this.router.navigate(['auth/verify'], {
            queryParams: { returnUrl: state.url },
            queryParamsHandling: 'merge',
          });
          return false;
        }

        // There is a user that has verified their email and is trying to access the verify page
        if (user.emailVerified && state.url.includes('auth/verify')) {
          this.router.navigate(['']);
          return false;
        }

        // There is a user that is logged in and is trying to access the login or create pages
        if (
          state.url.includes('auth/login') ||
          state.url.includes('auth/create')
        ) {
          this.router.navigate(['']);
          return false;
        }

        return true;
      })
    );
  }
}

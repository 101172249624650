import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { GetTokenOptions } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import {
  FirebaseMessaging,
  Notification,
  NotificationActionPerformedEvent,
  NotificationReceivedEvent,
  TokenReceivedEvent,
} from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import {
  MessageType,
  NotificationData,
  NotificationPayload,
} from '@sharendipity/models';
import { environment } from '@sharendipity/shared/utils/environment';
import { firstValueFrom, map, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationReceived$ = new Subject<Notification>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private firestoreService: FirestoreService
  ) {}

  public async initializePushNotifications() {
    await FirebaseMessaging.requestPermissions();
    await this.getToken();

    await FirebaseMessaging.removeAllListeners();
    await FirebaseMessaging.addListener(
      'tokenReceived',
      (event: TokenReceivedEvent) => {
        this.registerToken(event.token);
      }
    );
    await FirebaseMessaging.addListener(
      'notificationReceived',
      (event: NotificationReceivedEvent) => {
        this.notificationReceived$.next(event.notification);
      }
    );
    await FirebaseMessaging.addListener(
      'notificationActionPerformed',
      (event: NotificationActionPerformedEvent) => {
        this.handleActionPerformed(event.notification);
      }
    );

    if (!Capacitor.isNativePlatform()) {
      navigator.serviceWorker.addEventListener(
        'message',
        (event: MessageEvent) => {
          const { data }: { data: NotificationPayload } = event;

          // This will happen in the notificationRecieved listener when the app is open
          if (data.messageType === MessageType.PUSH_RECEIVED) return;

          // This will happen when a notification is clicked in the notification center
          if (data.messageType === MessageType.NOTIFICATION_CLICKED) {
            this.handleActionPerformed(data);
            return;
          }

          this.notificationReceived$.next(data.notification as Notification);
        }
      );
    }
  }

  public async getToken(): Promise<void> {
    const options: GetTokenOptions = {
      vapidKey: environment.vapidKey,
    };
    const { token } = await FirebaseMessaging.getToken(options);
    await this.registerToken(token);
  }

  /**
   * Register a token to a user
   * @param token
   */
  private async registerToken(token: string) {
    const userId = await firstValueFrom(
      this.authService.user$.pipe(map((user) => user.uid))
    );

    const tokenQuery = await this.firestoreService.collection(
      `tokens`,
      where(
        'user',
        '==',
        this.firestoreService.documentReference(`users/${userId}`)
      ),
      where('token', '==', token)
    );
    const tokens = await firstValueFrom(tokenQuery);
    if (!tokens.length) {
      this.firestoreService.addDocument(`tokens`, {
        created: new Date(),
        user: this.firestoreService.documentReference(`users/${userId}`),
        token,
      });
    }
  }

  /**
   * If a url exists in the data of the notification, route accordingly
   * @param tappedNotification
   */
  private handleActionPerformed(notification: Notification) {
    const { url = null } = notification.data as NotificationData;
    if (url) {
      this.router.navigate([url]);
    }
  }
}

import { Timestamp } from '@angular/fire/firestore';
import { CollectionItem } from '../item/item';

export interface Collection {
  created: Timestamp | Date;
  default?: boolean;
  description?: string;
  id?: string;
  items?: CollectionItem[];
  owner: string;
  title: string;
}

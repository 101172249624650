import { ValidationMessage } from '@sharendipity/models';
import { AuthTranslations } from './auth-translations';

export const AuthValidationMessages: ValidationMessage = {
  email: [
    { type: 'required', message: AuthTranslations.emailRequired },
    { type: 'pattern', message: AuthTranslations.emailInvalid },
  ],
  password: [
    { type: 'required', message: AuthTranslations.passwordRequired },
    { type: 'minlength', message: AuthTranslations.passwordLengthInvalid },
  ],
  passwordConfirm: [
    { type: 'required', message: AuthTranslations.confirmPasswordRequired },
    { type: 'minlength', message: AuthTranslations.passwordLengthInvalid },
    { type: 'mustMatch', message: AuthTranslations.passwordMismatch },
  ],
};

import { firestore } from 'firebase-admin';
import { Timestamp } from '@angular/fire/firestore';

export interface SharendipityUser {
  id?: string;
  created?: Timestamp;
  verifyEmailSent?: Timestamp;
  name: string;
  email: string;
  phoneNumber?: string;
  photoURL?: string;
  defaultCollection: firestore.DocumentReference;
}

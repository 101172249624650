import { Injectable } from '@angular/core';
import {
  ActionCodeSettings,
  Auth,
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthCredential,
  OAuthProvider,
  reload,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateCurrentUser,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { SharendipityUser } from '@sharendipity/models';
import {
  AnalyticEventName,
  AuthTranslations,
  SignInProvider,
} from '@sharendipity/shared/utils/constants';
import { environment } from '@sharendipity/shared/utils/environment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AnalyticsService } from '../analytics/analytics.service';
import { FirestoreService } from '../firestore/firestore.service';

type LoginTypes = GithubAuthProvider | GoogleAuthProvider;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user$: Observable<User>;
  public sharendipityUser$: Observable<SharendipityUser>;

  constructor(
    private analyticsService: AnalyticsService,
    private auth: Auth,
    private firestoreService: FirestoreService,
    private translateService: TranslateService
  ) {
    this.user$ = new Observable<User>((observable) =>
      this.auth.onAuthStateChanged(
        (user) => observable.next(user as User),
        (err) => observable.error(err),
        () => observable.complete()
      )
    );

    this.sharendipityUser$ = this.user$.pipe(
      switchMap((user: User) =>
        this.firestoreService.document<SharendipityUser>(`users/${user.uid}`)
      )
    );
  }

  public createAccount(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  public errorMessageFromCode(errorCode: string): string | undefined {
    switch (errorCode) {
      case AuthErrorCodes.INVALID_PASSWORD:
      case AuthErrorCodes.USER_DELETED:
        return this.translateService.instant(
          AuthTranslations.incorrectEmailPassword
        );
      case AuthErrorCodes.EMAIL_EXISTS:
        return this.translateService.instant(AuthTranslations.emailExists);
      // Some cases we do not want to show an error message
      case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
        return undefined;
      default:
        return this.translateService.instant(AuthTranslations.unableToSignIn);
    }
  }

  public async sendEmailVerification(user: User): Promise<void> {
    try {
      const actionCodeSettings: ActionCodeSettings = {
        url: `https://${
          environment.production ? '' : 'dev.'
        }sharendipity.com/collections`,
        iOS: {
          bundleId: 'com.appgenies.sharendipity',
        },
        android: {
          packageName: 'com.appgenies.sharendipity',
          installApp: true,
          minimumVersion: '12',
        },
        // We can change this when we update our app to handle codes
        handleCodeInApp: false,
        // When multiple custom dynamic link domains are defined, specify which
        // one to use.
        dynamicLinkDomain: `sharendipity${
          environment.production ? '' : 'dev'
        }.page.link`,
      };
      await sendEmailVerification(user, actionCodeSettings);
      this.firestoreService.setDocument(`users/${user.uid}`, {
        verifyEmailSent: new Date(),
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  private async nativeAuth(
    provider: LoginTypes
  ): Promise<UserCredential | null> {
    let nativeAuthResult = null;

    try {
      // 1. Sign in on the native layer
      switch (provider.providerId) {
        case SignInProvider.GITHUB:
          nativeAuthResult = await FirebaseAuthentication.signInWithGithub({
            scopes: provider.getScopes(),
          });
          break;
        case SignInProvider.GOOGLE:
          nativeAuthResult = await FirebaseAuthentication.signInWithGoogle({
            scopes: provider.getScopes(),
          });
          break;
      }

      // 2. Once we have the user authenticated on the native layer, authenticate it in the web layer
      if (nativeAuthResult && nativeAuthResult !== null) {
        const auth = this.auth;
        let nativeCredential: OAuthCredential | null = null;

        switch (provider.providerId) {
          case SignInProvider.GITHUB:
            nativeCredential = GithubAuthProvider.credential(
              nativeAuthResult.credential?.accessToken ?? ''
            );
            break;
          case SignInProvider.GOOGLE:
            nativeCredential = GoogleAuthProvider.credential(
              nativeAuthResult.credential?.idToken,
              nativeAuthResult.credential?.accessToken
            );
            break;
        }

        if (nativeCredential) {
          // 3. Sign in on the web layer using the access token we got from the native sign in
          const credential = await signInWithCredential(auth, nativeCredential);
          this.analyticsService.logEvent(AnalyticEventName.LOGIN);
          return credential;
        }

        return Promise.reject('Native Credential is null');
      } else {
        return Promise.reject('null nativeAuthResult');
      }
    } catch (error) {
      console.log(error);
      return Promise.reject('null nativeAuthResult');
    }
  }

  public async signInWithApple(): Promise<UserCredential | null> {
    const provider = new OAuthProvider('apple.com');
    return this.handleProviderSignIn(provider);
  }

  public async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    const credential = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    this.analyticsService.logEvent(AnalyticEventName.LOGIN);
    return credential;
  }

  public async signInWithGithub(): Promise<UserCredential | null> {
    const provider = new GithubAuthProvider();
    provider.addScope('read:user');
    provider.addScope('user:email');
    return this.handleProviderSignIn(provider);
  }

  public async signInWithGoogle(): Promise<UserCredential | null> {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return this.handleProviderSignIn(provider);
  }

  public async signOut(): Promise<void> {
    try {
      // * 1. Sign out on the native layer
      await FirebaseAuthentication.signOut();
      // * 2. Sign out on the web layer
      await signOut(this.auth);
    } catch (error) {
      console.error(`Error signing out ${error}`);
    }
  }

  /**
   * Handle showing a popup with on a browser or a redirect if on a native platform
   *
   * @param provider
   * @returns
   */
  private async handleProviderSignIn(
    provider: LoginTypes
  ): Promise<UserCredential | null> {
    try {
      if (Capacitor.isNativePlatform()) {
        return this.nativeAuth(provider);
      } else {
        const credential = await signInWithPopup(this.auth, provider);
        this.analyticsService.logEvent(AnalyticEventName.LOGIN);
        return credential;
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async refreshUser() {
    this.auth.currentUser && (await reload(this.auth?.currentUser));
    await updateCurrentUser(this.auth, this.auth.currentUser);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  connectAuthEmulator,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  enableMultiTabIndexedDbPersistence,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import {
  connectStorageEmulator,
  getStorage,
  provideStorage,
} from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';
import { environment } from '@sharendipity/shared/utils/environment';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
  resolvePersistenceEnabled = resolve;
});

@NgModule({
  imports: [
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = Capacitor.isNativePlatform()
        ? initializeAuth(getApp(), {
            persistence: indexedDBLocalPersistence,
          })
        : getAuth();

      if (environment.emulators) {
        connectAuthEmulator(auth, 'http://localhost:9099');
      }

      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions(
        undefined,
        environment.emulators
          ? undefined
          : `https://${environment.production ? '' : 'dev.'}sharendipity.com`
      );
      if (environment.emulators) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }

      return functions;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.emulators) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }

      enableMultiTabIndexedDbPersistence(firestore).then(
        () => resolvePersistenceEnabled(true),
        () => resolvePersistenceEnabled(false)
      );

      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.emulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }

      return storage;
    }),
  ],
  providers: [ScreenTrackingService, UserTrackingService],
})
export class SharedFeatureAngularFireModule {}

import { Injectable } from '@angular/core';
import {
  getDownloadURL,
  listAll,
  ref,
  Storage,
  StringFormat,
  uploadString,
} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: Storage) {}

  async listImages(storagePath: string) {
    const storageRef = ref(this.storage, storagePath);
    return listAll(storageRef);
  }

  async getDownloadURL(storagePath: string) {
    const storageRef = ref(this.storage, storagePath);
    return getDownloadURL(storageRef);
  }

  async uploadString(imageDataUrl: string, storagePath: string) {
    const newImageRef = ref(this.storage, storagePath);
    return await uploadString(newImageRef, imageDataUrl, StringFormat.DATA_URL);
  }

  async getImage(imageUrl: string) {
    const newImageRef = ref(this.storage, imageUrl);
    return await getDownloadURL(newImageRef);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule } from '@ionic/angular';
import { SharedFeatureAngularFireModule } from '@sharendipity/shared-feature-angular-fire';
import { AuthGuard } from '@sharendipity/shared/data-access/guards';
import { SharedFeatureNgxTranslateModule } from '@sharendipity/shared/feature/ngx-translate';
import { environment } from '@sharendipity/shared/utils/environment';
import { BaseShellComponent } from './base-shell.component';

export const baseShellRoutes: Route[] = [
  {
    path: '',
    component: BaseShellComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('@sharendipity/base/home/shell').then(
            (m) => m.BaseHomeShellModule
          ),
      },
      {
        path: 'auth',
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('@sharendipity/base/auth/shell').then(
            (m) => m.BaseAuthShellModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  declarations: [BaseShellComponent],
  imports: [
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
    CommonModule,
    IonicModule,
    RouterModule.forRoot(baseShellRoutes),
    SharedFeatureNgxTranslateModule,
    SharedFeatureAngularFireModule,
  ],
})
export class BaseShellModule {}

import { Injectable } from '@angular/core';
import { DocumentReference, orderBy, where } from '@angular/fire/firestore';
import {
  BasicUserInfo,
  Chat,
  Message,
  SharendipityUser,
} from '@sharendipity/models';
import { reduce } from 'lodash-es';
import {
  concatAll,
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  toArray,
} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(
    private authService: AuthService,
    private firestoreService: FirestoreService
  ) {}

  /**
   * Get a specific chat
   */
  getChat(chatId: string): Observable<Message[] | null> {
    return this.authService.user$.pipe(
      filter((user) => !!user),
      switchMap((user) => {
        return this.firestoreService.document<Chat>(`chats/${chatId}`).pipe(
          map((chat) => {
            // Chat does not exist
            if (!chat) return null;

            // User is not a participant of the chat
            const isParticipant = chat.participants.some(
              (participant) => participant.id === user.uid
            );
            if (!isParticipant) return null;

            return chat;
          })
        );
      }),
      switchMap((chat) => {
        if (!chat) return of(null);

        return this.firestoreService.collection<Message>(
          `chats/${chatId}/messages`,
          orderBy('created')
        );
      })
    );
  }

  /**
   * Get chat list for currently logged in user
   * @returns
   */
  getChats(): Observable<Chat[]> {
    return this.authService.user$.pipe(
      filter((user) => !!user),
      switchMap((user) => {
        return this.firestoreService.collectionGroupStream<Chat>(
          `chats`,
          where(
            'participants',
            'array-contains',
            this.firestoreService.documentReference(`users/${user.uid}`)
          ),
          orderBy('created', 'desc')
        );
      }),
      switchMap(async (chats) =>
        chats.map(async (chat) => {
          const userId = await firstValueFrom(
            this.authService.user$.pipe(map((user) => user.uid))
          );
          const participantsPromise = chat.participants.map(
            async (participant) =>
              await firstValueFrom(
                this.firestoreService
                  .document<SharendipityUser>(`users/${participant.id}`)
                  .pipe(
                    map(
                      ({ id, email, name = null, photoURL = null }) =>
                        ({
                          name,
                          id,
                          photoURL,
                          email,
                        } as BasicUserInfo)
                    )
                  )
              )
          );

          const participants = await Promise.all(participantsPromise);
          const participantsInfo = reduce(
            participants,
            (acc, user: Partial<SharendipityUser>) => ({
              ...acc,
              [user.id as string]: { ...user },
            }),
            {}
          );
          return {
            ...chat,
            participantsInfo,
            participantsInfoArray: participants.filter(
              (user) => user.id !== userId
            ),
          };
        })
      ),
      switchMap((promises) => from(promises).pipe(concatAll(), toArray()))
    );
  }

  async newMessage(message: string, recipient: DocumentReference) {
    const sender = await firstValueFrom(
      this.authService.user$.pipe(
        map((user) =>
          this.firestoreService.documentReference(`users/${user.uid}`)
        )
      )
    );
    const check = await this.firestoreService.collectionDocs(
      `chats`,
      where('participants', 'in', [[sender, recipient]])
    );

    if (check.empty) {
      const newChat = await this.firestoreService.addDocument(`chats`, {
        created: new Date(),
        participants: [sender, recipient],
      });
      await this.sendChatMessage(newChat.id, message, sender);
      return;
    }

    await this.sendChatMessage(check.docs[0].id, message, sender);
  }

  async sendChatMessage(
    chatId: string,
    message: string,
    sender: DocumentReference | null = null
  ) {
    if (!sender) {
      sender = await firstValueFrom(
        this.authService.user$.pipe(
          map((user) =>
            this.firestoreService.documentReference(`users/${user.uid}`)
          )
        )
      );
    }
    return this.firestoreService.addDocument(`chats/${chatId}/messages`, {
      created: new Date(),
      sender,
      message,
    });
  }
}

import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { AuthService } from '@sharendipity/shared/data-access/services';

@Component({
  selector: 'sharendipity-base-shell',
  templateUrl: './base-shell.component.html',
  styleUrls: ['./base-shell.component.scss'],
})
export class BaseShellComponent {
  constructor(
    private authservice: AuthService,
    private platform: Platform,
    private router: Router,
    private zone: NgZone
  ) {
    this.initializeApp();
  }

  private async initializeApp() {
    await this.platform.ready();
    await SplashScreen.hide();

    // App listener for deep links
    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        await this.authservice.refreshUser();
        const slug = this.determineNavigateUrl(event.url);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private determineNavigateUrl(eventUrl: string): string {
    const url = decodeURIComponent(eventUrl);
    /**
     * Example URL below for continue URL
     * ...&continueUrl=https://sharendipitydev.page.link?link=https://dev.sharendipity.com/collections&apn=com.appgenies.sharendipity&amv&ibi=com.appgenies.sharendipity&ifl=https://dev.sharendipity.com/collections&lang=en
     * slug = collections
     */
    if (url.includes('continueUrl=')) {
      const slug = url
        .split('continueUrl=')
        .pop()
        ?.split('link=')
        .pop()
        ?.split('.com/')
        .pop()
        ?.split('&')[0];
      return slug ?? '';
    }

    // Example url: https://sharendipity.com/tabs/tab2
    // slug = /tabs/tab2
    const slug = url.split('.com/').pop();
    return slug ?? '';
  }
}
